import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'
import useSiteSettings from '~utils/useSiteSettings'
import { Plus } from '~components/Svg'
import Section from '~components/Section'
import { css } from '@emotion/react'
import SanityLink from '~components/SanityLink'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'

const Header = ({ className, hideTitle, location, hideX }) => {
	const {courseMenu, subMenu, pastYears, siteTitle} = useSiteSettings()
	const [menuOpen, setMenuOpen] = useState(false)
	const [integer, setInteger] = useState(undefined)
	const [level, setLevel] = useState(undefined)
	const [search, setSearch] = useState()

	const images = [ [...courseMenu], [...subMenu], [...pastYears] ]
	
	const selectItem = (l, i) => {
		setLevel(l)
		setInteger(i)
	}

	useEffect(() => {
		setTimeout(() => {
			setMenuOpen(false)
		}, 350)
	}, [location.pathname])

	return(
		<>
			<StyledHeader className={className}>
				<Section>
					<Row>
						<HeaderTitle menuOpen={menuOpen} to={'/'} className={'h1'}>
							<DesktopTitle hide={hideTitle}>{siteTitle}</DesktopTitle>
							<MobileTitle hide={hideTitle}>
								<Thin>VCA </Thin>Design <br />
								&amp; Production <br />
								<Thin>2022</Thin>
							</MobileTitle>
						</HeaderTitle>
						{!hideX &&
							<MenuToggle onClick={() => setMenuOpen(!menuOpen)} menuOpen={menuOpen}>
								<StyledPlus />
							</MenuToggle>
						}
					</Row>
				</Section>
			</StyledHeader>
			<Menu menuOpen={menuOpen}>
				<MenuHeader>
					<Section>
						<Row>
							<MenuTitle to={'/'} className='h1'>
								<DesktopTitle>{siteTitle}</DesktopTitle>
								<MobileTitle>
									<Thin>VCA </Thin>Design <br />
									&amp; Production <br />
									<Thin>2022</Thin>
								</MobileTitle>
							</MenuTitle>
						</Row>
					</Section>
				</MenuHeader>
				<MenuLinks>
					<Courses>
						{courseMenu.map((c, i) => (
							<Hover 
								key={c.button?.text} 
								onMouseEnter={() => selectItem(0, i)}
							>
								<MenuLink 
									link={c.button?.link} 
									className='h1'
									active={level === 0 && integer === i}
									color={c.button?.link?.document?.keyColour}
								>
									{c.button?.text}
								</MenuLink>
							</Hover>
						))}
					</Courses>
					<MenuLinksBottom>
						<SubMenu>
							{subMenu.map((sm, i) => (
								<Hover 
									key={sm.button?.text} 
									onMouseEnter={() => selectItem(1, i)}
								>
									<MenuLink 
										link={sm.button?.link} 
										className='h1'
										active={level === 1 && integer === i}
										color={sm.button?.link?.document?.keyColour}
									>
										{sm.button?.text}
									</MenuLink>
								</Hover>
							))}
							<div>
								<Search 
									onMouseEnter={() => selectItem('search')}
									to='/search'
									className='h1'
								>
									Search
								</Search>
							</div>
						</SubMenu>
						<PastYears>
							{pastYears.map((y, i) => (
								<Hover 
									key={y.button?.text} 
									onMouseEnter={() => selectItem(2, i)}
								>
									<MenuLink 
										link={y.button?.link} 
										className='h1'
										active={level === 2 && integer === i}
										color={y.button?.link?.document?.keyColour}
									>
										{y.button?.text}
									</MenuLink>
								</Hover>
							))}
						</PastYears>
					</MenuLinksBottom>
				</MenuLinks>
				{images?.map((l, levelInteger) => (
					l.map((levelImage, imageInteger) => (
						<BackgroundImage 
							key={levelImage.image?.asset?._id}
							visible={level === levelInteger && integer === imageInteger} 
							image={levelImage.image} 
							cover
						/>
					))
				))}
			</Menu>
		</>
	)
}

const StyledHeader = styled.header`
  position: relative;
  top: 0;
	left: 0;
  right: 0;
  z-index: 100;
	pointer-events: none;
	margin-top: 43px;
	${mobile}{
		margin-top: 68px;
	}
`
const MenuHeader = styled(StyledHeader)`
`
const Row = styled.div`
	grid-column: span 12;
	display: flex;
	justify-content: space-between;
	align-items: center;
`
const Title = styled(Link)`
	color: var(--white);
	text-transform: uppercase;
	display: block;
`
const HeaderTitle = styled(Title)`
	opacity: ${ props => props.menuOpen ? '0' : '1'};
	pointer-events: ${ props => props.menuOpen ? 'none' : 'all'};
	color: var(--transGrey);
	transition: opacity 0.3s;
`
const MenuTitle = styled(Title)`
`
const DesktopTitle = styled.div`
	opacity: ${ props => props.hide ? '0' : '1'};
	pointer-events: ${ props => props.hide ? 'none' : 'all'};
	${mobile}{
		display: none;
	}
`
const MobileTitle = styled.div`
	opacity: ${ props => props.hide ? '0' : '1'};
	pointer-events: ${ props => props.hide ? 'none' : 'all'};
	display: none;
	${mobile}{
		display: block;
	}
`
const Thin = styled.span`
	font-weight: 300;
`
const MenuToggle = styled.button`
	display: block;
	pointer-events: all;
	position: fixed;
	right: 53px;
	width: 56px;
	height: 56px;
	z-index: 102;
	opacity: ${ props => props.menuOpen ? '1' : '0.5'};
	transform: ${ props => props.menuOpen ? 'rotate(45deg)' : 'rotate(0deg)'};
	transition: transform 0.25s, opacity 0.25s;
	:hover{
		opacity: 1;
	}
	${tablet}{
		right: 30px;
	}
	${mobile}{
		right: 20px;
		width: 25px;
		height: 25px;
		top: 30px;
	}
`
const StyledPlus = styled(Plus)`
`
const Menu = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: var(--black);
	opacity: ${ props => props.menuOpen ? '1' : '0'};
	pointer-events: ${ props => props.menuOpen ? 'all' : 'none'};
	transition: opacity 0.25s;
	display: flex;
	flex-direction: column;
	overflow: scroll;
`
const MenuLinks = styled.div`
	grid-column: span 12;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 53px 90px;
	position: relative;
	z-index: 2;
	${tablet}{
		padding: 0 30px 90px;
	}
	${mobile}{
		padding: 20px 20px 90px;
		flex: none;
	}
`
const MenuLinksBottom = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	flex: 1;
`
const Hover = styled.button`
	display: block;
	text-align: left;
	${mobile}{
		margin-bottom: 24px;
	}
`
const MenuLink = styled(SanityLink)`
	display: block;
	color: ${ props => props.active ? props.color : 'var(--white)'};
	transition: color 0.25s, opacity 0.25s;
	opacity: ${ props => props.active ? '1' : '0.5'};
`
const Courses = styled.div`
	padding-top: 2px;
	margin-bottom: 120px;
	${mobile}{
		margin-bottom: 0px;
	}
`
const Search = styled(Link)`
	display: block;
	opacity: 0.5;
	margin-bottom: 24px;
	:hover{
		opacity: 1;
	}
`
const SubMenu = styled.div`
	grid-column: span 12;
`
const PastYears = styled.div`
	grid-column: span 12;
`
const BackgroundImage = styled(Image)`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	opacity: ${ props => props.visible ? '0.4' : '0'};
	transition: opacity 0.25s;
	${mobile}{
		display: none;
	}
`

Header.propTypes = {
	className: PropTypes.string,
	hideTitle: PropTypes.bool,
	location: PropTypes.object,
	hideX: PropTypes.bool,
}

export default Header
